import "./App.css";
import React, { useState, useRef, useEffect } from "react";
import { ArcwareInit } from "@arcware-cloud/pixelstreaming-websdk";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {GoScreenFull} from "react-icons/go";
import {HiSpeakerXMark} from  "react-icons/hi2";
import {IoMdContract} from "react-icons/io";
import {BsFillMicFill, BsFillEmojiSmileFill,BsMap, BsFillVolumeUpFill, BsSend} from "react-icons/bs";
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
let arcwareAudioId = "myAudio"
let message;
var a = window.location.search;
var b = a.split("?");
if(b.length>1){
 
 
  message = b[1];

}
let playListVideo =["https://static.videezy.com/system/resources/previews/000/012/740/original/Particles_4_-_30s_-_4k_res.mp4"];


function App() {
  const videoContainerRef = useRef(null);
  const [arcwareApplication, setArcwareApplication] = useState(null);
  const [applicationResponse, setApplicationResponse] = useState("");
  const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [microphoneMute, setMicrophoneMute] = useState(false);
  const [loading, setLoading] = useState(true);
  const [videoPlay, setVideoPlay] = useState();


  const handleSendCommand = (descriptor) => {
    arcwareApplication?.emitUIInteraction(descriptor);
  };


  useEffect(()=>{
    var prevVideo = localStorage.getItem("preVideoPlay");
    playListVideo.filter(e=> e!== prevVideo);
    var item = playListVideo[Math.floor(Math.random()*playListVideo.length)];
    localStorage.setItem("preVideoPlay", item);
    
    setVideoPlay(item);


    },[])

  const screenSize = () => {
  

    var elem  = document.documentElement;       
  
    setIsFullScreen(!isFullScreen);

  
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitEnterFullScreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
  
  
       if (document.fullscreenElement !== null) {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) { /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE11 */
    document.msExitFullscreen();
  }
       }
  };
  const toggleMute = () => {
  //  var x = document.getElementById(arcwareAudioId);
    
  console.log(videoContainerRef.current)
    setIsMuted(!isMuted);
  
 //  x.muted = !isMuted;
   videoContainerRef.current.muted = !isMuted;
  
  };

  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );


useEffect(()=>{
  const loadCall=async()=>{
await delay(30200).then(()=> {setLoading(false);});
  

  }

  loadCall();
},[])

  useEffect(() => {

    const startCall=async()=>{


      console.log("calling")
    if(message !== undefined){
    const { Config, PixelStreaming, Application } = ArcwareInit(
        {
          shareId: message
        },
        {
          initialSettings: {
            StartVideoMuted: true,
            AutoConnect: true,
            AutoPlayVideo: true,
          },
          settings: {
            infoButton: false,
            micButton: false,
            audioButton: false,
            fullscreenButton: false,
            settingsButton: false,
            connectionStrengthIcon: true
          },
        }
      );
      
    setArcwareApplication(Application);
    Application.getApplicationResponse((response) =>
      setApplicationResponse(response)
    );



    // Append the application's root element to the video container ref
    if (videoContainerRef?.current) {
      console.log("appendChild");
      videoContainerRef.current.appendChild(Application.rootElement);

    }

  }}
  startCall();
}, []);
  
  // console.log("applicationResponse", applicationResponse);

  return (
    <div>


<>
   <div
        ref={videoContainerRef}
        style={{ width: "100vw", height: "100vh",background:"black" }}
      />

{loading == true ?(<>

  <div  style={{ width: "100vw", position:"fixed",top:0,bottom:0,left:0,right:0,height: "100vh",background:"black",zIndex:"100" }} >
      <Row >
      <Col  xs={4} md={4}></Col>
        <Col  xs={4}md={{ span:2, offset: 1 }} >
          
          <Button className="loadingmargin">
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        &nbsp;&nbsp;Loading...
      </Button>
      
      </Col>
        <Col  xs={4} md={5}></Col>
      </Row>
   
</div>

  
</>):(


<Row style={{position:"fixed",zIndex:"100",bottom:10,left:0,right:0}}>
 
 

 
 <Col md={12} lg={12} xl={12}  style={{ 
    display: "flex",  justifyContent: "flex-end",
    }} >




  <div style={{
          //  border:"2px solid green",
              paddingRight:"12px", 
              paddingLeft:"24px"
            }} >
   <button 
 
 style={{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "50%",
  border: "none",
  backgroundColor: "#101010",
  color: "#ffffff",
  opacity: "0.5",
  height:"37px",
  width:"37px"
 }}
 onClick={toggleMute} 
    >{!isMuted ? 
 
 < BsFillVolumeUpFill   style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />
 : 
 < HiSpeakerXMark style={{color:"#ffffff",fontWeight: "bold", fontSize:"20px" }} />
 
 }
 </button>
 
 </div>
 
 
 <div   style={{
          //  border:"2px solid green",
              paddingRight:"12px", 
              paddingLeft:"24px"
            }} >
 
 <button 
  // style={{borderRadius:"24px",background:"#36454F",opacity:"0.5",border:"2px solid #36454F",color:"#fff"}} 
  
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "none",
    backgroundColor: "#101010",
    color: "#ffffff",
    opacity: "0.5",
    height:"37px",
    width:"37px"
   
 }}
 
  onClick={()=>screenSize()} >
     {isFullScreen ? 
         < IoMdContract style={{
          color:"#ffffff",fontWeight: "bold",
          fontSize: "20px",
          }}  /> 
       : 
          <GoScreenFull    style={{
          color:"#ffffff",fontWeight: "bold",
           fontSize: "20px",
          }}  />
     }
   </button>
   </div>


 

 
   </Col>
 
 </Row>
)}

     
    </>


</div>
  );
}

export default App;